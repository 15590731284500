body {
  margin: 0;
  font-weight: 400;
  color: #373737;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "sans-serif", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 25px;
  color: #777777;
  font-size: 15px;
}

h1 {
  font-size: 44px;
  font-weight: 600;
  line-height: 56px;
  color: #ab5393;
}

h2 {
  font-size: 38px;
  font-weight: 600;
  line-height: 56px;
  color: #ab5393;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  -webkit-box-shadow: 0 0 20px #ccc;
  box-shadow: 0 0 20px #ccc;
}

.sticky-top {
  position: static;
  top: -10px;
}

.bg-gray {
  background-color: #f7f7f9;
}

.bg-white {
  background-color: #fff;
}

.navbar {
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

.navbar-brand-img {
  max-width: 250px;
}

.sticky .navbar-brand-img {
  max-width: 150px;
}

.navbar-nav .nav-link {
  color: #485474 !important;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  padding: 15px 15px !important;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link:hover {
  color: #24bbab !important;
  border-bottom: 2px solid;
}

.services-card {
  border: 1px solid #24bbab2b;
  border-radius: 3px;
  margin: 0 0 30px;
}

.services-card svg {
  color: #24bbab;
  border: 2px solid #24bbab42;
  display: inline-block;
  border-radius: 50%;
  line-height: 75px;
  font-size: 20px;
  width: 82px;
  height: 82px;
  padding: 20px 20px;
}

.services-card .services-card-desc {
  text-align: justify;
}

#home-area {
  padding: 10px 0 98px;
}

#home-area img {
  max-width: 100%;
  height: auto;
  width: 100%;
}

#home-area .no-margin {
  margin-left: 0px;
  margin-right: 0px;
}

#home-area .no-margin div {
  position: absolute;
  padding: 82px 0px 0 80px;
}

#home-area .no-margin p {
  font-size: 32px;
  color: #fff;
  text-align: justify;
}

#home-area .no-margin span {
  color: #ab5393;
}

.home-content {
  padding: 12% 0 0;
}

#about-area {
  padding: 120px 0 100px;
}

.about-img img {
  margin: 0 auto;
  border: 17px solid #ab53934d;
}

#services-area {
  padding: 120px 0 92px;
}

#contact-area {
  padding: 110px 0 88px;
}

#contact-area .contact-form {
  margin-bottom: 30px;
}

#contact-area .contact-form input {
  height: 50px;
  border-radius: 25px;
  padding-left: 20px;
  border: 1px solid #24bbab42;
}

#contact-area .contact-form textarea {
  border-radius: 25px;
  padding-left: 20px;
  resize: none;
  border: 1px solid #24bbab42;
}

#contact-area .contact-form button {
  padding: 10px 30px;
  color: #fff;
  background-color: #24bbab;
  font-weight: 500;
  border: 0;
  border-radius: 25px;
}

#contact-area .contact-form .error-style {
  margin-bottom: 20px;
  border-color: rgb(191, 22, 80) rgb(191, 22, 80) rgb(191, 22, 80) rgb(236, 89, 144);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-image: none 100% / 1 / 0 stretch;
  background: rgb(251, 236, 242) none repeat scroll 0% 0%;
}

#contact-area .contact-form input.error-style:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

#contact-area .contact-detail .social-media ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#contact-area .contact-detail .social-media ul li {
  display: inline-block;
  padding: 5px 5px 5px 0px;
}

#contact-area .contact-detail .social-media ul li a {
  display: block;
  border: 2px solid #24bbab;
  color: #24bbab;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 16px;
}

#contact-area .contact-detail svg {
  color: #24bbab;
}

@media (max-width: 575px) {
  #home-area {
    padding: 10px 0 75px;
  }

  #home-area img {
    height: 170px;
  }

  #home-area .no-margin div {
    position: relative;
	margin-top: 25px;
	padding: 0 15px 0 15px;
  }

  #home-area .no-margin p {
    font-size: 22px;
    color: #24bbab;
    text-align: justify;
  }

  #about-area {
    padding: 80px 0 100px;
  }
}

@media (min-width: 576px) {
  #home-area {
    padding: 10px 0 75px;
  }

  #home-area img {
    height: 170px;
  }

  #home-area .no-margin div {
    position: relative;
	margin-top: 25px;
	padding: 0 15px 0 15px;
  }

  #home-area .no-margin p {
    font-size: 22px;
    color: #24bbab;
    text-align: justify;
  }

  #about-area {
    padding: 80px 0 100px;
  }
}

@media (min-width: 768px) {
  #home-area {
    padding: 10px 0 75px;
  }

  #home-area img {
    height: auto;
  }

  #home-area .no-margin div {
    position: absolute;
	margin-top: 25px;
	padding: 0 15px 0 15px;
  }

  #home-area .no-margin p {
    font-size: 22px;
    color: #fff;
    text-align: justify;
  }

  #about-area {
    padding: 80px 0 100px;
  }
}

@media (min-width: 992px) {
  #home-area {
    padding: 10px 0 75px;
  }

  #home-area img {
    height: auto;
  }

  #home-area .no-margin div {
    position: absolute;
	margin-top: 50px;
	padding: 0 15px 0 15px;
  }

  #home-area .no-margin p {
    font-size: 22px;
    color: #fff;
    text-align: justify;
  }

  #about-area {
    padding: 80px 0 100px;
  }
}

@media (min-width: 1200px) {
  #home-area {
    padding: 10px 0 75px;
  }

  #home-area img {
    height: auto;
  }

  #home-area .no-margin div {
    position: absolute;
	margin-top: 105px;
	padding: 0 15px 0 15px;
  }

  #home-area .no-margin p {
    font-size: 22px;
    color: #fff;
    text-align: justify;
  }

  #about-area {
    padding: 80px 0 100px;
  }
}

@media (min-width: 1600px) {
  #home-area {
    padding: 10px 0 75px;
  }

  #home-area img {
    height: auto;
  }

  #home-area .no-margin div {
    position: absolute;
	margin-top: 160px;
	padding: 0 15px 0 15px;
  }

  #home-area .no-margin p {
    font-size: 22px;
    color: #fff;
    text-align: justify;
  }

  #about-area {
    padding: 80px 0 100px;
  }
}